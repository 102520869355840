import React, { Component } from "react";
import "./App.css";

interface AppState {
  username: string;
  password: string;
  loggingIn: boolean;
}

class App extends Component<{}, AppState> {
  state: AppState = {
    username: "",
    password: "",
    loggingIn: false
  };

  render() {
    const { username, password, loggingIn } = this.state;

    return (
      <div className="container">
        <img src={"/api/images/LOGON"} style={{ display: "none" }} />
        <div className={"login" + (loggingIn ? " wobble" : "")}>
          <input
            type="text"
            value={username}
            disabled={loggingIn}
            onChange={event =>
              this.setState({
                username: event.currentTarget.value,
                loggingIn: false
              })
            }
            placeholder="Benutzername"
          />
          <input
            type="password"
            value={password}
            disabled={loggingIn}
            onChange={event =>
              this.setState({
                password: event.currentTarget.value,
                loggingIn: false
              })
            }
            placeholder="Passwort"
          />
          <br />
          <button
            disabled={!(username && password) || loggingIn}
            onClick={this.handleLogin}
          >
            Login
          </button>
        </div>
      </div>
    );
  }

  private handleLogin = () => {
    this.setState({ loggingIn: true });
    setTimeout(() => this.setState({ loggingIn: false }), 1000);
  };
}

export default App;
